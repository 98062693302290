<template>
  <section id="bigIntroHero" class="hero is-small">
    <div class="hero-body">
      <div id="heroContent" class="container">
        <pac-columns class="is-vcentered">
          <div class="column is-half has-text-centered-mobile introText">
            <h1 class="title is-spaced" style="font-size: 2.4rem;">
              <span>Take control</span> of your packing.
            </h1>
            <p class="subtitle">
              Paccurate reduces shipping costs and wasted material by finding the optimal way to pack every shipment. For parcel, LTL, and FTL.
            </p>
            <p class="subtitle" style="line-height: 2rem; font-size: 0.9rem;">
              <a href="https://paccurate.typeform.com/to/q8LTQqQq" class="button is-primary bigButton">Get Started for Free</a>
            </p>
          </div>
          <div id="randoPack" class="column is-half has-text-centered-mobile">
            <div id="randoInner">
              <random-pack />
            </div>
          </div>
        </pac-columns>

        <pac-columns class="packResults">
          <div class="column is-one-half" />
          <div class="column is-one-half">
            <div class="resultsContain">
              <span>
                Live request stats: {{ itemsPackedNum }} random items packed in {{ itemsPackedSpeed }} seconds, {{ itemsPackedVol }}% volume used
              </span>
              <div v-if="playLine" id="loaderBar" class="run-animation" />
            </div>
          </div>
        </pac-columns>
      </div>
    </div>
    <div class="testimonial-content">
      <div class="container has-text-centered">
        <p>
          "The cost to value ratio is off the charts. I've never seen anything like it."
        </p>
        <span>&#8212;&nbsp;<strong>Rick Gemereth</strong> | CIO, <a
          href="http://www.lionel.com/"
        >Lionel</a></span>
      </div>
    </div>
    <div class="hero-foot">
      <div class="hero-foot-inner">
        <div class="container">
          <div class="columns is-vcentered">
            <div id="DQSdesc" class="column is-one-half">
              <h2>Developer Quickstart</h2>
              <p>Want to get your feet wet right away? Execute this command from your shell. <a href="https://manage.paccurate.io/sign_up">Grab an API key</a> to unlock request limits.</p>
            </div>
            <div class="column is-one-half">
              <!-- eslint-disable -->
              <client-only>
              <prism id="apiRequest" language="json">
                curl -X POST -d '{
                    "itemSets": [{
                    "refId": 0,
                    "dimensions": {"x": 5.5, "y": 6, "z": 6},
                    "quantity": 3
                }],
                    "boxTypeSets": ["fedex"]
                }' api.paccurate.io/
              </prism>
              </client-only>
              <!--  eslint-enable -->
              <button
                v-clipboard:copy="message"
                type="button"
                class="snipCopy"
              ><font-awesome-icon icon="clone" /> Copy</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="green">
      <div class="container has-text-centered">
        Reducing wasted space and material = a smaller carbon footprint.
      </div>
    </div> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import RandomPack from '~/components/RandomPack.vue'
const RandomPack = () => import('~/components/RandomPack.vue')

export default {
  components: {
    RandomPack
  },
  data () {
    return {
      message: 'curl -X POST -d \'{ "itemSets": [{ "refId": 0, "dimensions": {"x": 5.5, "y": 6, "z": 6}, "quantity": 3 }], "boxTypeSets":["fedex"] }\' api.paccurate.io/'
    }
  },
  computed: {
    ...mapState([
      'itemsPackedNum',
      'itemsPackedSpeed',
      'itemsPackedVol',
      'playLine'
    ])
  },
  methods: {
    async copySnip (text) {
      try {
        await this.$copyText(text)
      } catch (e) {

      }
    }
  }
}
</script>

<style type="text/css">
#rapidFireWeekly{
  background: #fff;
}
</style>
