<template>
  <div>
    <intro-hero />
    <why-you-need-it />
    <the-perks />
    <what-its-for />
    <trusted />
    <recent-news :posts="posts" />
    <!-- <section class="testimonial has-text-left">
      <div class="container" />
    </section> -->
    <!-- <section id="theFlow">
      <div class="container">
        <h2 class="level-item has-text-centered title is-3">
          How does it work?
        </h2>
        <p class="subtitle narrow-sub">
          Paccurate will always try to generate the
          <strong>best acceptable</strong> packing solution, based on your
          packing goals and constraints. The more information you provide, the
          more ideal the result. Here's what happens during a single request:
        </p>
        <img src="@/assets/theflow.gif">
      </div>
    </section> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IntroHero from '~/components/homepage/IntroHero.vue'
import ThePerks from '~/components/homepage/ThePerks.vue'
import WhyYouNeedIt from '~/components/homepage/WhyYouNeedIt.vue'
const WhatItsFor = () => import('~/components/homepage/WhatItsFor.vue')
const Trusted = () => import('~/components/homepage/Trusted.vue')
const RecentNews = () => import('~/components/RecentNews.vue')

export default {
  head () {
    return {
      title: 'Reduce waste and costs with smart cartonization'
    }
  },
  components: {
    IntroHero,
    RecentNews,
    ThePerks,
    WhatItsFor,
    WhyYouNeedIt,
    Trusted
  },
  computed: {
    ...mapState([
      'posts'
    ])
  }
}
</script>
